import React from "react";
const AboutOne = () => {
  return (
    <div className="container">
      <div className="row row--20 align-items-center">
        <div className="col-lg-6">
          <div className="thumbnail">
            <img
              className="w-100 h-100"
              src="/assets/images/about/about-3.jpg"
              alt="Hakkımızda Görsel"
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="about-inner inner">
            <div className="section-title">
              <span className="subtitle">Kayka Ahşap</span>
              <h2 className="title">Kurumsal Değerlerimiz</h2>
              <p className="description">
                Ticarette Kayka Ahşap Olarak; Samimiyet, Güven, Titizlik
                İlkeleri ile çıktığımız yolda uzun yılların bize kattığı tecrübe
                ile, kalite standartlarımızı hep bir adım öteye taşıyarak
                müşterilerimize hep en sağlıklı ve en kaliteli ürünü sunduk
              </p>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="about-us-list">
                  <h5 className="title">Tecrübeli Ekip</h5>
                  <p>
                    Kayka Ahşap ; Ekip çalışması ruhu ile, üstlenmiş olduğu
                    projelerde kendini de geliştirmeyi görev kabul etmiştir. Bu
                    doğrultuda faaliyetler tüm hızıyla devam etmektedir.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="about-us-list">
                  <h5 className="title">Pazar Bilgisi</h5>
                  <p>
                    Uzun yıllardır piyasada faaliyet göstermemizin neticesi
                    olarak pazardaki rakiplerimizden bizi ayıran en önemli nokta
                    da pazar hakkındaki bilgimizdir.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="about-us-list">
                  <h5 className="title">Yüksek Verim</h5>
                  <p>
                    Öncelikli hedefimiz yüksek verimlilik. Tüm çalışma
                    sistemimizi bu hedefe ulaşma doğrultusunda
                    şekillendiriyoruz.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="about-us-list">
                  <h5 className="title">Kalite Değerlerimiz</h5>
                  <p>
                    Ticarette Kayka Ahşap Olarak; Samimiyet, Güven, Titizlik
                    İlkeleri ile çıktığımız yolda uzun yılların bize kattığı
                    tecrübe ile kalite standartlarımızı hep bir adım öteye
                    taşıyarak müşterilerimize hep en sağlıklı ve en kaliteli
                    ürünü sunduk.
                  </p>
                </div>
              </div>
            </div>
            <div className="purchase-btn mt--50">
              <a className="btn-transparent" href="/about">
                Hakkımızda
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AboutOne;
