import React, { Component , Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import Slider from "react-slick";

import { slideSlick } from "../page-demo/script";
import BlogContent from "../elements/blog/BlogContent";
import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/Footer";
import Helmet from "../component/common/Helmet";
import { FiChevronUp } from "react-icons/fi";
import AboutOne from "../blocks/about/AboutOne";
import ServiceTwo from "../elements/service/ServiceTwo";
const Portfolio_image = <img src="/assets/images/portfolio/interior/portfolio-1.jpg" alt="Portfolio" />;
const Portfolio_image2 = <img src="/assets/images/portfolio/interior/portfolio-2.jpg" alt="Portfolio" />;
const Portfolio_image3 = <img src="/assets/images/portfolio/interior/portfolio-3.jpg" alt="Portfolio" />;
const Portfolio_image4 = <img src="/assets/images/portfolio/interior/portfolio-4.jpg" alt="Portfolio" />;
const Portfolio_image5 = <img src="/assets/images/portfolio/interior/portfolio-5.jpg" alt="Portfolio" />;
const Portfolio_image6 = <img src="/assets/images/portfolio/interior/portfolio-6.jpg" alt="Portfolio" />;
const Portfolio_image7 = <img src="/assets/images/portfolio/interior/portfolio-7.jpg" alt="Portfolio" />;
const PortfolioList = [
    {
        image: Portfolio_image,
        title: 'PVC İÇ ODA KAPILARI',
    },
    {
        image: Portfolio_image2,
        title: 'LAKE İÇ ODA KAPILARI',
    },
    {
        image: Portfolio_image3,
        title: 'LAMİMANT İÇ ODA KAPILARI',
    },
    {
        image: Portfolio_image4,
        title: 'MELAMİN İÇ ODA KAPILARI',
    },
    {
        image: Portfolio_image5,
        title: 'DOLAP KAPAKLARI',
    },
    {
        image: Portfolio_image6,
        title: 'MUTFAK DOLAPLARI',
    },
    {
        image: Portfolio_image7,
        title: 'AMERİKAN PANEL İÇ ODA KAPILARI',
    },
]

const PortfolioList2 = [
    {
        image: Portfolio_image,
        category: 'Design',
        title: 'Landcape Design',
        description: 'Lorem ipsum dolor sit amet, conse. Lorem ipsum dolor sit amet, conse.'
    },
    {
        image: Portfolio_image2,
        category: 'Design',
        title: 'Landcape Design',
        description: 'Lorem ipsum dolor sit amet, conse. Lorem ipsum dolor sit amet, conse.'
    },
    {
        image: Portfolio_image3,
        category: 'Interior',
        title: 'Home Interior',
        description: 'Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, conse.'
    },
    {
        image: Portfolio_image4,
        category: 'Interior',
        title: 'Home Interior',
        description: 'Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, conse.'
    },
    {
        image: Portfolio_image2,
        category: 'Lighting',
        title: 'Bedroom Lighting',
        description: 'Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, conse.'
    },
    {
        image: Portfolio_image3,
        category: 'Lighting',
        title: 'Bedroom Lighting',
        description: 'Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, conse.'
    }
    
]

const SlideList = [
    {
        textPosition: 'text-left',
        bgImage: 'bg_image--31',
        category: '',
        title: 'Kayka Ahşap.',
        description: 'İç Mekan Kapıları & Dekorasyon',
        buttonText: 'Dijital Katalog',
        buttonLink: '/assets/images/katalog.pdf'
    },
    
]




class CorporateBusiness extends Component{
    constructor () {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){

        var namesItemOne = [
            'The Philosophy Of business analytics',
            'Fast-Track Your business',
            'Lies And Damn Lies About business',
            'The Ultimate Deal On business',
        ];
        var namesItemTwo = [
            'Proof That business Really Works',
            'Here Is What You Should Do For Your business',
            'The Hidden Mystery Behind business',
        ];
        
        const PostList = BlogContent.slice(0 , 3);

        return(
            <Fragment> 
                <Helmet pageTitle="Kayka Ahşap" />

                {/* Start Header Area  */}
                <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>
                {/* End Header Area  */}

                {/* Start Slider Area   */}
                <div className="slider-wrapper" >
                    <div className="slider-activation">
                        <Slider className="rn-slick-dot dot-light" {...slideSlick}>
                            {SlideList.map((value , index) => (
                                <div className={`slide slide-style-2 slider-box-content without-overlay d-flex align-items-center justify-content-center bg_image ${value.bgImage}`} key={index} data-black-overlay="2">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className={`inner ${value.textPosition}`}>
                                                    {value.category ? <span>{value.category}</span> : ''}
                                                    {value.title ? <h1 className="title ">{value.title}</h1> : ''}
                                                    {value.description ? <p className="description">{value.description}</p> : ''}
                                                    {value.buttonText ? <div className="slide-btn"><a className="btn-default" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
                {/* End Slider Area   */}
                



                {/* Start About Area */}
                <div className="about-area rm-about-style-2 ptb--120 bg_color--5" id="about">
                    <div className="about-wrapper">
                        <AboutOne />
                    </div>
                </div>
                {/* End About Area */} 


                <div className="service-area ptb--120  bg_color--5">
                    <ServiceTwo />
                </div>



                {/* Start Portfolio Area */}
                <div className="portfolio-area pb--120 bg_color--1">
                    <div className="container">
                        <div className="row mt_dec--30">
                            {PortfolioList.map((value , index) => (
                                <div className="col-lg-6 col-md-6 col-12 mt--30" key={index}>
                                    <div className="im_portfolio">
                                        <div className="thumbnail_inner">
                                            <div className="thumbnail">
                                                
                                                    {value.image}
                                                   
                                            </div>
                                        </div>
                                        <div className="content">
                                            <div className="inner">
                                                <div className="portfolio_heading">

                                                    <h4 className="title">{value.title}</h4>
                                                </div>
                                                <div className="portfolio_hover">
                                                    <p>{value.description}</p>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Portfolio Area */}



                {/* Start Footer Style  */}
                <Footer />
                {/* End Footer Style  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </Fragment>
        )
    }
}
export default CorporateBusiness;